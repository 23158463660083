// import React from "react";
// import config from "../../config";
// import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
// import "./css/CollageComponent.css";
// const CollageComponent = ({ count, imageUrl, Delete, item, editItem }) => {
//   return (
//     <div className="CollageComponentMain">
//       {Delete && (
//         <div className="CollageComponentHeding">
//           {" "}
//           <h1>Collage {count} </h1>{" "}
//           {editItem && <EditOutlined className="icon" onClick={editItem} />}
//           <DeleteOutlined className="icon" onClick={Delete} />
//         </div>
//       )}
//       <div className="CollageComponentimg">
//         <img src={`${config.imageUrl}${imageUrl}`} />
//       </div>
//       <div className="" style={{ marginLeft: "10px" }}>
//         <p>
//           <span style={{ fontWeight: "bold" }}>Description : </span>{" "}
//           {item.COLLAGE_DESCRIPTION}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default CollageComponent;


import React from "react";
import config from "../../config";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./css/CollageComponent.css";
import { Flex } from "antd";

const CollageComponent = ({ count, imageUrl, Delete, item, editItem }) => {
  return (
    <div className="CollageComponentMain">
      {Delete && (
        <div className="CollageComponentHeding">
          <h1>Collage {count}</h1>
          <Flex align="center" gap={20}> 
          {editItem && <EditOutlined className="icon" onClick={editItem} />}
          <DeleteOutlined className="icon" onClick={Delete} />
          </Flex>
        </div>
      )}
      <div className="CollageComponentimg">
        <img src={`${config.imageUrl}${imageUrl}`} alt={`Collage ${count}`} />
      </div>
      <div style={{ marginLeft: "10px" }}>
        <p>
          <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
          {item.COLLAGE_DESCRIPTION}
        </p>
      </div>
    </div>
  );
};

export default CollageComponent;
