import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import {
  LinkOutlined,
  AppstoreOutlined,
  AppstoreFilled,
} from "@ant-design/icons";
import { Input, Flex, Button, message, notification } from "antd";
import { Spin } from "antd";
import { Modal } from "antd"; 
import "./ShoppingCollage.css";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
import Collage from "../Collage/Collage";
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
import Item from "antd/es/list/Item";
const ShoppingCollage = ({ SelectUserData }) => {
  const [UserShoppingCollageData, setUserShoppingCollageData] = useState([]);
  const [linkInputs, setLinkInputs] = useState(Array(8).fill(""));
  const [disableButton, setdisableButton] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [requiredLinks, setRequiredLinks] = useState(0);
  const [dispalycollagecretaion, setdispalycollagecretaion] = useState(false);
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [product, setproduct] = useState([]);
  const [productimagelink, setproductimagelink] = useState([]);
  const [dispalProduct, setdispalProduct] = useState(false);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(false);
  const [axis, setaxis] = useState(0);
  const [sendfileDataList, setsendfileDataList] = useState([]);
  const [displaLinkData, setdisplaLinkData] = useState(true);
  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const [uploadDragData, setUploadDragData] = useState(null);// Track the item to be edited
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);  // Modal visibility
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); 
  const [selectedItem, setSelectedItem] = useState(null); 
  const [UserShoppingCollageData1, setUserShoppingCollageData1] = useState([]); 
  const fcmToken = sessionStorage.getItem("fcmToken");
  function modifyUrls(urls) {
    const baseUrl = ` "https://skandaqa.stylz.in"`;

    return urls.map((url) => {
      if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, ""); // Remove the base URL
      }
      return url; // If it doesn't match, return the original URL
    });
  }
  const CollageNotifyBackend = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Stylz",
          NOTI_BODY: "Your lookbook is ready",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: SelectUserData?.STYLIST_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_ID.toString(),
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
      }
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
// for edit 
  const showEditConfirmation = (item) => {
    setEditItem(item); // Store the item to edit
    // handleModalOk(item);
    setIsEditModalVisible(true); // Show the modal
  };
//for edit 
  const handleModalOk = () => {
    setIsEditModalVisible(false); // Close the modal
    // Proceed with the edit logic
    console.log("Editing item:", editItem);
    // setEditItem(item);
    // Add your edit logic here
  };
//fot edit 
  const handleModalCancel = () => {
    setIsEditModalVisible(false); // Close the modal without editing
    setEditItem(null); // Reset the edit item
  };

  // for upload collage
  const handleSubmitButtonClick = () => {
    // Show confirmation modal when "Upload Collage" is clicked
    setIsModalVisible(true);
  };
// for upload collage
  const handleModalOk1 = () => {
    setIsModalVisible(false); // Close the modal
    SubmmitCollage(); // Proceed with submitting the collage
  };
// for upload collage
  const handleModalCancel1 = () => {
    setIsModalVisible(false); // Close the modal without submitting
  };

  // Show the delete confirmation modal
  const showDeleteConfirmation = (item) => {
    setSelectedItem(item); // Set the selected item for deletion
    setIsDeleteModalVisible(true); // Open the delete modal
  };

  // Handle OK button for delete confirmation and proceed with deletion
  const handleDeleteModalOk = () => {
    if (selectedItem) {
      setIsDeleteModalVisible(false); // Close the modal
      console.log("Item deleted:", selectedItem); // Add your delete logic here
      // Proceed with your delete logic (e.g., call delete API or remove item from state)
      handleDelete(selectedItem);
      // setUserShoppingCollageData1((prevData) => prevData.filter((item) => item !== selectedItem));
    }
    setSelectedItem(null); // Reset the selected item after deletion
  };

  // Handle Cancel button for delete confirmation
  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false); // Close the delete modal
    setSelectedItem(null); // Reset the selected item
  };


  const CollageUserNotify = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  function matchImagesToProducts(selectedImagesList, productsJson) {
    // Parse the JSON string into a JavaScript object
    const products = JSON.parse(productsJson);

    // Initialize an array to store matching products
    const matchedProducts = [];

    // Loop through each image in the selectedImagesList
    selectedImagesList.forEach((imageUrl) => {
      // Loop through the products
      products.forEach((product) => {
        // Check if the imageURL[0] of the product matches the current imageUrl
        if (product.imageURL[0] === imageUrl) {
          // If it matches, push the product to the matchedProducts array
          matchedProducts.push(product);
        }
      });
    });

    // Return the matched products array
    return matchedProducts;
  }

  function extractUrls(data) {
    try {
      // Check if data is a string; if so, parse it into an object
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;

      // Extract the 'url' field from each object in the array
      const urls = parsedData.map((item) => {
        if (item.url) {
          return item.url; // Return the URL if it exists
        } else {
          return item; // Return the entire item if URL does not exist
        }
      });

      return urls;
    } catch (error) {
      console.error("Invalid data provided", error);
      return [];
    }
  }
  const UploadSingleColllage = async (
    path,
    DESCRIPTION,
    fileDataList,
    fileList, 
    imageId = null 
  ) => {
    console.log(product, "Product........");
    // const selectedImagesList = JSON.parse(
    //   sessionStorage.getItem("linkSelectedData")
    // );
    // const filteredLinks = linkInputs.filter((link) => link.trim() !== "");
    // const matchedProducts = matchImagesToProducts(
    //   modifyUrls(productimagelink),
    //   selectedImagesList
    // );
    
    console.log(imageId, "fileDataList1.....");

    console.log(path, DESCRIPTION, fileDataList, fileList, "fileDataList.....");
    try {
      const formData = new FormData();
      console.log(product, "product........");
      if (path) {
        formData.append("picture", path);
      }
      console.log(fileDataList, "sendfileDataList.........sendfileDataList");

      // const linkSelectedData=sessionStorage.getItem('linkSelectedData');
      console.log(
        path,
        "image paths",
        productimagelink,
        modifyUrls(productimagelink),
        fileDataList,
        extractUrls(fileDataList)
      );
      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),
          STYLIST_ID: SelectUserData.stylist_id,
          IMAGE_ID : imageId,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: modifyUrls(fileList),
          LINK_LIST: `[${extractUrls(fileDataList).join(", ")}]`,
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_ID,
          AXIS: 0,
          SCRAPE_DATA: "",
          JsonResponse: fileDataList,
          COLLAGE_DESCRIPTION: DESCRIPTION,
        })
      );

      const response = await axios.post(
        `${config.baseURL}shoppingCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        console.log(response, "lsmdlnlsnlnl....colaage.......");
        getUserShoppingCollageData();
        setloading(false);
        setdispalycollagecretaion(false);
        setLinkInputs(Array(8).fill(""));
        setproductimagelink([]);
        setproduct([]);

        message.success(" Collage Created successfully");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${1}`
      );
      console.log(response, "get collage data");
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");
        setUserShoppingCollageData(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
    setdisableButton(true);
  };
  const getUserShoppingCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${0}`
      );
      // console.log(response)
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");

        setUserShoppingCollageData(response.data.recordset);
        if (response.data.recordset.length === 0) {
          console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }

    setdisableButton(true);
  };

  const CreateCollageUseLink = async (urls) => {
    console.log([urls], "isdjifljsdiopmp");
    try {
      const params = new URLSearchParams();

      // Add each URL to the 'urls' key in the params
      urls.filter(Boolean).forEach((url) => {
        params.append("urls", url);
      });
      const response = await axios.post(`${config.baseURL}scrape`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      console.log(response, "kdsnknskdnk....out");

      // Handle the response as needed
      if (response?.data) {
        const links = response.data.map((data) => data.imageURL);
        console.log(response.data, "kdsnknskdnk....out", links);
        setproduct(response.data);
        setproductimagelink(links);
        setloading(false);
      }

      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const handleLinkChange = (index, e) => {
    const newLinkInputs = [...linkInputs];
    newLinkInputs[index] = e.target.value;
    setLinkInputs(newLinkInputs);
    // console.log("Updated linkInputs:", newLinkInputs);
  };
  const handleTemplateSelection = (template) => {
    setRequiredLinks(template);
  };
  const handleTemplateSelectionsubmmit = (template) => {
    setTemplateSelection(template);
    if (UserShoppingCollageData.length < 3) {
      setRequiredLinks(template);

      // Determine which indexes should be highlighted
      const requiredIndexes = Array.from({ length: template }, (_, i) => i);
      const providedLinksCount = linkInputs
        .slice(0, template)
        .filter((link) => link.trim() !== "").length;

      // console.log("Current Link Inputs:", linkInputs);
      console.log(providedLinksCount, template);
      // if (providedLinksCount < template) {
      //   setHighlightedIndexes(requiredIndexes);
      //   // setValidationError(`Please provide ${template} file links.`);
      //   message.error(`Please provide ${template} file links.`);
      //   setdispalProduct(false);
      // } else {
      setHighlightedIndexes([]);
      setValidationError("");
      CreateCollageUseLink(linkInputs);
      setloading(true);
      setdispalProduct(true);
      // console.log(linkInputs.filter(Boolean), "linkss");
      // }
    } else {
      message.error("Only 3 collages can be created. Your limit reached.");
    }
  };
  const CollageNotify = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const updateCollage = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),

        COLLAGE: "Stylist completed your outfit",
        SERVICE_ID: SelectUserData.SERVICE_ID.toString(),
        CUSTOMER_ID: SelectUserData.user_id.toString(),
        STYLIST_TOKEN: fcmToken,
        CUSTOMER_TOKEN: SelectUserData.fcm_token,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        notification.info({
          message: "Success",
          description: "I have sent the collage to the user.",
        });
        await CollageNotify();
        CollageNotifyBackend();
        CollageUserNotify();
        console.log(response.data, "kdsnknskdnk....out");
        setaxis(1);
        await getUseronlineCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  useEffect(() => {
    // getUserShoppingCollageData(SelectUserData?.user_id);
    getUserShoppingCollageData();
    handleTemplateSelection(5);
    if (uploadDragData) {
      console.log("uploadDragImageList data outside callback:", uploadDragData);
    }
  }, [SelectUserData, loading, uploadDragData]);

  const uploadCreatedCollage = async (
    file,
    DESCRIPTION,
    fileDataList,
    fileList, 
    imageId,
  ) => {
    console.log(file, ";;;;;.......................");
    console.log(imageId, "fileDataList333333.....");
    await UploadSingleColllage(file, DESCRIPTION, fileDataList, fileList, imageId);
  };
  const handleDelete = async (record) => {
    console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        await getUserShoppingCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const SubmmitCollage = async () => {
    await collageImagesList();
  };
  const CreateCollageButton = () => {
    console.log(TemplateSelection, "TemplateSelection");
    handleTemplateSelection(TemplateSelection >= 5 ? TemplateSelection : 0);
  };
  const setuploadDragImageList = () => {};
  console.log(editItem,'dishioshoif...')
  return (
    <div>
      {displaLinkData && axis === 0 ? (
        <>
          {((UserShoppingCollageData.length < 3 &&
            SelectUserData.status !== 4)||editItem) && (
              <Collage
                sampleImages={product}
                defaultFrameSize={requiredLinks ? requiredLinks : 5}
                SelectUserData={SelectUserData}
                handleSingleCollage={handleTemplateSelectionsubmmit}
                uploadDragImageList={(data) => setuploadDragImageList(data)}
                SendinputText={(data) => {
                  setCOLLAGE_DESCRIPTION(data);
                  console.log(data, "shopping ....");
                }}
                SendImageLinks={(data) => setproductimagelink(data)}
                sendfileDataList={(data) => setsendfileDataList(data)}
                // uploadCreatedCollage={uploadCreatedCollage}
                uploadCreatedCollage={(file, description, fileDataList, fileList) => {
                  const imageId = editItem?.IMAGE_ID || null; // Extract IMAGE_ID from editItem
                  console.log({ editItem, product, imageId }, "Inside Collage Component");
                  uploadCreatedCollage(file, description, fileDataList, fileList, imageId);
                }}
                editItem={editItem}
              />
            )}{" "}
          {SelectUserData.status === 4 && (
            <>
              <p style={{ textAlignLast: "center" }}>User Service is Closed</p>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="shoppongCollageContainer">
        {dispalProduct && (
          <>
            {" "}
            {loading && product ? (
              <div className="loading">
                <Spin tip="Loading..." style={{ marginTop: "5%" }} />
              </div>
            ) : (
              <>
                {" "}
                <Collage
                  sampleImages={product}
                  defaultFrameSize={requiredLinks ? requiredLinks : 5}
                  SelectUserData={SelectUserData}
                  handleSingleCollage={handleTemplateSelectionsubmmit}
                  uploadCreatedCollage={uploadCreatedCollage}
                  uploadDragImageList={(data) => setuploadDragImageList(data)}
       
                />
              </>
            )}
          </>
        )}

        {UserShoppingCollageData.length > 0 && (
          <div className="userProfileMain" style={{ marginBottom: "10pxli" }}>
            <h1 className="heading"> Created Collage List</h1>
          </div>
        )}

<Modal
        title="Edit Confirmation"
        open={isEditModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to edit this item?</p>
      </Modal>

      <Modal
        title="Are you sure you want to submit?"
        open={isModalVisible}
        onOk={handleModalOk1} // Proceed with submission when "Yes" is clicked
        onCancel={handleModalCancel1} // Close the modal when "No" is clicked
        okText="Yes"
        cancelText="No"
      >
        <p>Once you submit, it will be sent to the user.</p>
      </Modal>

      <Modal
        title="Delete Confirmation"
        open={isDeleteModalVisible}
        onOk={handleDeleteModalOk}
        onCancel={handleDeleteModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>

        {axis === 0 && SelectUserData?.status !== 4 ? (
          <Flex direction="row" className="Collagelist" wrap="nowrap">
            {UserShoppingCollageData &&
              UserShoppingCollageData.map((item, index) => (
                <CollageComponent
                  key={index}
                  count={index + 1}
                  imageUrl={item?.IMAGE_NAME}
                  // Delete={() => {
                  //   handleDelete(item);
                  // }}
                  Delete={() => showDeleteConfirmation(item)} 
                  // editItem={() => setEditItem(item)}
                  editItem={() => showEditConfirmation(item)}
                  item={item}
                />
              ))}
          </Flex>
        ) : (
          <div className="onlineProduct">
            {UserShoppingCollageData.map((item, index) => (
              <CollageComponent
                key={index}
                count={index + 1}
                imageUrl={item?.IMAGE_NAME}
                // Delete={() => {
                //   handleDelete(item);
                // }}
                item={item}
              />
            ))}
          </div>
        )}
        {SelectUserData?.status === 4 ? (
          <></>
        ) : (
          <>
            {axis === 0 && (
              <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
                <Button
                  className="button"
                  disabled={
                    !(UserShoppingCollageData.length == 3 && disableButton)
                  }

                  onClick={handleSubmitButtonClick}
                  // onClick={
                  //   UserShoppingCollageData.length === 3 && disableButton
                  //     ? SubmmitCollage
                  //     : null
                  // }
                >
                  Upload Collage
                </Button>
              </Flex>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShoppingCollage;
